import React from 'react';
import troll from '../../public/troll.webp';
import * as styles from './troll.module.css';
import useFitText from 'use-fit-text';

const Troll = ({
  children,
  trollWidth,
  additionalFontSize,
  containerHeight,
  containerWidth,
  top,
  right,
  bottom,
  left,
}) => {
  const { fontSize, ref } = useFitText({ resolution: 1, minFontSize: 1 });

  return (
    <div
      className={styles.trollContainer}
      style={{
        width: trollWidth || '100%',
        top: top || null,
        left: left || null,
        right: right || null,
        bottom: bottom || null,
      }}
    >
      <img className={styles.trollImage} src={troll} alt="" />
      <blockquote
        className={`${styles.speech} ${styles.bubble}`}
        ref={ref}
        style={{
          fontSize: `calc(${fontSize} + ${additionalFontSize || 70}%)`,
          height: `${containerHeight || '15'}%`,
          width: `${containerWidth || '20'}%`,
        }}
      >
        {children}
      </blockquote>
    </div>
  );
};

export default Troll;
